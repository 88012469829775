import React from 'react';
import Navbar from '../../widget/Navbar';
import ActivityTable from './widgets/ActivityTable';
import Sidebar from './widgets/Sidebar';

const DashboardPage = () => {
    const handleRegisterAccount = () => {
        window.location.href = '/register-subscriber';
    }
    return (
        <div className='mb-8'>
            <Sidebar/>
            <div className="ml-64 py-2 sm:px-4">
                <Navbar/>
                <div className="lg:px-12 mt-4 sm:px-4 md:px-6">
                    <p className='text-2xl' style={{fontFamily: "Red Hat Display"}}>Admin's Dashboard</p>
                    <div className='mt-4 flex flex-col gap-2'>
                        <p className="text-lg font-bold">User Activities</p>
                        <p>View and filter activities in CONSOLE-D.</p>
                        <ActivityTable/>
                    </div>
                    <div className="flex flex-col gap-2 mt-8 w-2/5">
                        <p className="text-lg font-bold">Register free account</p>
                        <p>Create new user accounts while simultaneously assigning them to predefined subscription plans.</p>
                        <button className='mt-4 bg-orange text-white w-48 tetx-sm font-bold py-2 px-4 rounded-md' onClick={handleRegisterAccount}>Register account</button>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default DashboardPage;