import React, { useEffect, useState } from "react";
import Navbar from "../widget/Navbar";
import api from './../AxiosConfig';
import QueryHistoryWidget from "../widget/QueryHistoryWidget";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { BookmarkIcon, ChevronDownIcon } from "@heroicons/react/outline";
import NoQueryHistory from "../assets/images/no-queries-found.png";
import FooterOrange from "../widget/FooterOrange";
import CustomCalendar from "../widget/CustomCalendar";

const QueryHistoryPage = () => {
    const [queryHistory, setQueryHistory] = useState([]);
    const [filter, setFilter] = useState('all');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const fetchQueryHistory = async () => {
        try {
            const params = {
                page,
                limit: 10,
                search: searchQuery,
                filter: filter,
            };

            if (filter === 'custom' && startDate && endDate) {
                params.startDate = startDate.toISOString();
                params.endDate = endDate.toISOString();
            }

            const response = await api.get(`/get-query-history`, { params });
            console.log(response)
            if (response.data["Base Output"].status_code === 200) {
                const parsedData = JSON.parse(response.data["Data"]);
                setQueryHistory(parsedData);
                setTotalPages(response.data.Pagination.total_pages);
            } else {
                console.error(response.data["Base Output"].message);
            }
        } catch (error) {
            console.error("Error fetching query history:", error);
        }
    };

    useEffect(() => {
        fetchQueryHistory();
    }, [filter, page, searchQuery, startDate, endDate]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleFilterChange = (newFilter, startDate = null, endDate = null) => {
        setFilter(newFilter);
        setStartDate(startDate);
        setEndDate(endDate);
        setPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleBookmarkNav = () => {
        window.location.href = '/bookmarked-queries';
    };

    const handleDateChange = (startDate, endDate) => {
        handleFilterChange('custom', startDate, endDate);
    };

    return (
        <div>
            <div className="py-2 sm:px-4">
                <Navbar />
                <div className="lg:px-12 md:px-12 sm:px-4 mt-8">
                    <p className="text-3xl font-bold">Query History</p>
                    <div className="mt-8 flex flex-col">
                        <div className="flex flex-row mb-4 justify-between lg:gap-6 md:gap-6 sm:gap-2">
                            <div className="flex flex-row flex-grow">
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search..."
                                    className="border rounded-md px-3 py-2 text-sm w-full"
                                />
                            </div>

                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold orange shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                        {filter === "this-week" ? "This Week" : capitalizeFirstLetter(filter)}
                                        <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 orange" />
                                    </MenuButton>
                                </div>

                                <MenuItems
                                    transition
                                    className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                                >
                                    <div className="py-1">
                                        <MenuItem>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => handleFilterChange('all')}
                                                    className={`text-start block w-full px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100 text-gray-900' : ''}`}
                                                >
                                                    All
                                                </button>
                                            )}
                                        </MenuItem>
                                        <MenuItem>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => handleFilterChange('today')}
                                                    className={`text-start block w-full px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100 text-gray-900' : ''}`}
                                                >
                                                    Today
                                                </button>
                                            )}
                                        </MenuItem>
                                        <MenuItem>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => handleFilterChange('yesterday')}
                                                    className={`text-start block w-full px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100 text-gray-900' : ''}`}
                                                >
                                                    Yesterday
                                                </button>
                                            )}
                                        </MenuItem>
                                        <MenuItem>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => handleFilterChange('this-week')}
                                                    className={`text-start block w-full px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100 text-gray-900' : ''}`}
                                                >
                                                    This Week
                                                </button>
                                            )}
                                        </MenuItem>
                                        <MenuItem className="border-t border-gray-200">
                                            <p className="block w-full px-4 pt-2 text-sm text-gray-700 font-bold">Custom Date</p>
                                        </MenuItem>
                                        <CustomCalendar onDateChange={handleDateChange} />
                                    </div>
                                </MenuItems>
                            </Menu>
                            <div>
                                <button className="hidden md:inline-block sm:hidden w-36 bg-orange px-4 py-2 text-white lg:text-sm md:text-sm sm:text-xs rounded-md" onClick={handleBookmarkNav}>
                                    View bookmarks
                                </button>

                                <button className="block md:hidden sm:inline-block p-2 text-orange bg-orange rounded-md" onClick={handleBookmarkNav}>

                                        <BookmarkIcon className="w-6 h-6 text-white" />
                                  
                                </button>
                            </div>
                        </div>

                        {/* Query History Display */}
                        {queryHistory.length === 0 ? (
                            <div className="mt-24 mb-24 flex flex-col gap-8 justify-center items-center align-middle">
                                <img src={NoQueryHistory} alt="No query history found" className="w-36" />
                                <p className="orange text-bold">No query history found</p>
                            </div>
                        ) : (
                            <div>
                                <div className="space-y-6">
                                    {queryHistory.map((item, index) => (
                                        <QueryHistoryWidget key={index} queryHistory={item} />
                                    ))}
                                </div>

                                <div className="flex justify-center items-center mt-12 space-x-4 mb-24">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={page === 1}
                                        className={`px-4 py-2 ${page === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-orange-100 rounded-md'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="orange" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061A1.125 1.125 0 0 1 21 8.689v8.122ZM11.25 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061a1.125 1.125 0 0 1 1.683.977v8.122Z" />
                                        </svg>
                                    </button>

                                    <p className="text-gray-500 text-sm">
                                        Page <span className="font-semibold">{page}</span> of <span className="font-semibold">{totalPages}</span>
                                    </p>

                                    <button
                                        onClick={handleNextPage}
                                        disabled={page === totalPages}
                                        className={`px-4 py-2 ${page === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-orange-100 rounded-md'}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="orange" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.811c0 .864.933 1.406 1.683.977l7.108-4.061a1.125 1.125 0 0 0 0-1.954l-7.108-4.061A1.125 1.125 0 0 0 3 8.689v8.122ZM12.75 16.811c0 .864.933 1.406 1.683.977l7.108-4.061a1.125 1.125 0 0 0 0-1.954l-7.108-4.061a1.125 1.125 0 0 0-1.683.977v8.122Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <FooterOrange />
        </div>
    );
};

export default QueryHistoryPage;
