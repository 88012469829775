import { useEffect, useState } from "react";
import api from "../AxiosConfig";
import QueryHistoryWidget from "../widget/QueryHistoryWidget";
import Navbar from "../widget/Navbar";
import NoQueryHistory from "../assets/images/no-queries-found.png";
import FooterOrange from "../widget/FooterOrange";
const BookmarkQueryPage = () =>{
    const [bookmark, setBookmark] = useState([]);
    useEffect(() => {
        const fetchBookmark = async () => {
            const response = await api.get('/get-bookmarks');   
            var data = response.data.Data
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }
            setBookmark(Array.isArray(data) ? data : []);
        };
        fetchBookmark();
    }, [bookmark]);

    return (
        <div>
            <div className="py-2 sm:px-4">
                <Navbar  />
                <div className="lg:px-12 md:px-12 sm:px-4 mt-8">
                    <p className="text-3xl font-bold">Bookmarks</p>
                    <div className="mt-12 space-y-6"> 
                        {bookmark.length === 0 ? (
                            <div className="mt-24 mb-24 flex flex-col gap-8 justify-center items-center align-middle">
                                <img src={NoQueryHistory} alt="No query history found" className="w-36" />
                                <p className="orange text-bold">No bookmarks found</p>
                            </div>
                        ):(
                            bookmark.map((item, index) => (
                                <QueryHistoryWidget key={index} queryHistory={item} />
                            ))
                        )}
                        
                    </div>
                </div>
            </div>
            <FooterOrange/>
        </div>
    )
}

export default BookmarkQueryPage;