import React, { useContext } from 'react';
import LogoImage from '../assets/images/logo.png';
import UserContext, { UserProvider } from '../context/UserContext'; // Adjust the path as needed
import UserWidget from './UserWidget';
import ContactUsButton from './ContactUsButton';


const Navbar = () => {
  const { user } = useContext(UserContext);
  
  const navigateToAnalysis = () => {
    window.location.href = '/';
  }
  return (
    <div className="flex justify-between items-center p-4 bg-white">
      <div className="flex items-center">
        <img onClick={navigateToAnalysis} src={LogoImage} alt="Logo" className="w-40 sm:w-24 lg:w-36" />
      </div>
      <div className="text-lg flex flex-row gap-2">
        <UserProvider>
            <UserWidget />
        </UserProvider>
      </div>
      
    </div>
  );
};

export default Navbar;
