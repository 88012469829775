import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getPaymentMethods } from "../../services/PaymentService";
import { UserIcon, PhoneIcon} from "@heroicons/react/outline";
import UserContext from '../../context/UserContext';
import '../../pages/css/Main.css';
import api from './../../AxiosConfig';
import Logo from "../../widget/Logo";
import Navbar from "../../widget/Navbar";
import { FiInfo } from "react-icons/fi";
import FooterLight from "../../widget/FooterLight";

const PaymentFormPage = () => {
    const location = useLocation();
    const { user, loading } = useContext(UserContext);
    const { subscription_type_id, subscriptionName, price, currency } = location.state || {};
    const [name, setName] = useState(`${user.firstname} ${user.lastname}`);
    const [phone, setPhone] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(name === "" || phone === "") {
            setErrorMessage("All fields must be filled out.");
            return;
        }
        try {
            const response = await api.post('/payment-request', {
                subscription_type_id: subscription_type_id.toString(),
                name: name,
                phone_number: phone,
            });
            const { url } = response.data;

            if (url) {
                window.location.href = url;
            } else {
                console.error("No URL found in the response.");
            }
        } catch (error) {
            console.error("Error making payment request:", error);
        }
    }

    const ErrorMessage = ({ message }) => {
        return (
            <div className="bg-red-100 border border-red-400 text-red-700 text-xs px-4 py-3 rounded relative flex flex-row gap-2" role="alert">
                <FiInfo className="inline-block align-middle mr-2" />
                <span className="inline-block align-middle">{message}</span>
            </div>
        );
    };
    return (
        <div>
            <div className="page-container relative h-screen py-2 sm:px-4">
                <Navbar />
                <div className="mt-6 page-container flex justify-center  py-2 px-4">
                    <div className="max-w-lg bg-white rounded-xl border border-gray-200 p-6 px-20 py-12 shadow-lg flex flex-col gap-6"> 
                        <div className="information">
                            <p style={{ fontFamily: "Red Hat Display" }} className="text-lg">You are paying</p>
                            <p className="text-4xl font-bold orange">{price} {currency}</p>
                            <p className="mt-4 text-md font-bold">Plan: {subscriptionName}</p>
                        </div>
                        
                        <div className="personal-information flex flex-col gap-2">
                            <p style={{ fontFamily: "Red Hat Display" }} className="text-lg">Personal Information</p>
                            {errorMessage && <ErrorMessage message={errorMessage} />}
                            <p className="text-sm">Name</p>
                            <div className="relative flex items-center">
                                <button type="button" className="absolute left-0 flex items-center ml-3 mb-2 orange">
                                    <UserIcon className="h-5 w-5" />
                                </button>
                                <input
                                    id="name"
                                    className="w-full mb-2 pl-10 px-4 py-4 border border-gray-300 rounded-md text-sm"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <p className="text-sm">Phone Number</p>
                            <div className="relative flex items-center">
                                <button type="button" className="absolute left-0 flex items-center ml-3 mb-2 orange">
                                    <PhoneIcon className="h-5 w-5" />
                                </button>
                                <input
                                    id="phone"
                                    className="w-full mb-2 pl-10 px-4 py-4 border border-gray-300 rounded-md text-sm"
                                    placeholder="+65 1111 1111"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>

                            <button onClick={handleSubmit} className="mt-4 bg-orange text-white py-3 rounded-lg">
                                <p>Proceed</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="background-image">
                <FooterLight/>
            </div>
        </div>
        
    );
};

export default PaymentFormPage;
