import React, { useContext, useState } from "react";
import Navbar from "../../widget/Navbar";
import UserContext from "../../context/UserContext";
import ProfileIcon from "../../assets/images/ProfileIcon.png";
import "../../pages/css/Main.css";
import { editProfile } from "../../services/UserService";
import NotSubscribedWidget from "../../widget/NotSubscribedWidget";
import FooterOrange from "../../widget/FooterOrange";

const ProfilePage = () => {
    const { user, loading } = useContext(UserContext);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phone_number: user.phone_number
    });


    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSaveChanges = async () => {
        try {
            setIsLoading(true);
            setError("Updating profile...");
            const response = await editProfile(formData);
            if (response["status_code"]  === 200) {
                setTimeout(() => {
                    window.location.reload();
                    setError('');
                }, 2000);
            } else {
                setError("Error updating profile");
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <div className="py-2 sm:px-4">
                <Navbar />
                {error && (
                    <div className={`font-bold error-message ${error === 'Updating profile...' ? 'generating' : 'active'}`}>
                        {error}
                    </div>
                )}
                <div className="lg:px-12 lg:mt-12 md:mt-8 sm:px-4 md:px-6">
                    <p style={{ fontFamily: "Red Hat Display" }} className="lg:text-3xl md:text-2xl sm:text-2xl mb-4">Account</p>
                    {user.role_name === 'free user' && <NotSubscribedWidget />}
                    <div>
                        <div className="lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col lg:gap-24 md:gap-16 lg:mt-8 md:mt-8">
                            <div className="lg:flex lg:flex-col md:flex md:flex-col sm:flex sm:flex-row gap-4">
                                <a href="/profile-page"><p className="lg:text-lg md:text-md sm:text-sm orange font-bold">Profile</p></a>
                                <a href="/account-settings"><p className="lg:text-lg md:text-md sm:text-sm text-gray-400 font-bold">Account Settings</p></a>
                                {user.role_name === 'subscriber' && <a href="/subscription-plan"><p className="lg:text-lg md:text-md sm:text-sm text-gray-400 font-bold">Subscription Plan</p></a>}
                                
                            </div>
                            <div>
                                <p style={{ fontFamily: "Red Hat Display" }} className="lg:text-2xl md:text-xl sm:text-lg sm:mt-8">Your Profile</p>
                                <div className="flex flex-col gap-4 mt-4">
                                    <div className="flex flex-row gap-4">
                                        <img src={ProfileIcon} alt="profile-icon" className="w-12" />
                                        <div className="flex flex-col">
                                            <p>{user.firstname} {user.lastname}</p>
                                            <p className="lg:text-sm text-gray-500">Joined since {formatDate(user.created_at)}</p>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ fontFamily: "Red Hat Display" }} className="mt-6 lg:text-2xl md:text-xl sm:text-lg">Personal Information</p>
                                <div className="flex flex-col gap-4 mt-6">
                                    <div className="flex flex-row gap-4 items-center">
                                        <p className="w-32">First Name: </p>
                                        <input
                                            name="firstname"
                                            className="bg-white border-gray-100 border-2 rounded-md shadow-sm px-4 py-2 text-black text-base w-64"
                                            value={formData.firstname}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                        <p className="w-32">Last Name: </p>
                                        <input
                                            name="lastname"
                                            className="bg-white border-gray-100 border-2 rounded-md shadow-sm px-4 py-2 text-black text-base w-64"
                                            value={formData.lastname}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                        <p className="w-32">Email: </p>
                                        <input
                                            name="email"
                                            className="bg-white border-gray-100 border-2 rounded-md shadow-sm px-4 py-2 text-black text-base w-64"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                        <p className="w-32">Phone Number: </p>
                                        <input
                                            name="phone_number"
                                            className="bg-white border-gray-100 border-2 rounded-md shadow-sm px-4 py-2 text-black text-base w-64"
                                            value={formData.phone_number}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <button 
                                    onClick={handleSaveChanges} 
                                    className={`bg-orange text-white text-sm rounded-md py-2 px-4 mt-8 ${isLoading ? 'cursor-not-allowed' : ''}`}
                                    disabled={isLoading}
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-48">
                <FooterOrange/>
            </div>
        </div>
    );
};

export default ProfilePage;
