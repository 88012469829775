import { useContext } from "react";
import UserContext from "../context/UserContext";
import { Navigate } from "react-router-dom";

const UnverifiedUserRoute = ({ children }) => {
    const { user, loading } = useContext(UserContext);
    console.log(user);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (user && user.isActive === true){
        return <Navigate to="/" replace />;
    }

    return user && user.role_name === "free user" && user.isActive === false ? children : <Navigate to="/" replace />;
};

export default UnverifiedUserRoute;